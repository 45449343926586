import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Timer from './components/Clock';
import Banner from './components/Banner';
import ReactGA from 'react-ga'; // Import React-GA

// Styled components
const C = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  margin: 0 auto;
  padding: 20px;
`;

const S = styled.section`
  display: inherit;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: #3c3c3c;
  border-radius: 15px;
  margin: 0 auto;
  padding: 35px;
  gap: 30px;
`;

const PlusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PlusButton = styled.button`
  font-size: 24px;
  background-color: transparent;
  color: #ffffff;
  border: none;
  cursor: pointer;

  &:hover {
    color: #007bff;
  }
`;

const DescriptionApp = styled.h2`
  background-color: transparent;
  color: #ffffff;
`;

const Appli = styled.div`
  text-align: center;
  color: #ffffff;
`;

// Initialize Google Analytics
const initializeGA = () => {
  const TRACKING_ID = "G-RTLJYP690F"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
};

// App component
function App() {
  const [timers, setTimers] = useState([<Timer key={0} />]);
  const appRef = useRef(null); // Ref for the outermost container
  const containerRef = useRef(null); // Ref for the container that holds the timers

  useEffect(() => {
    initializeGA(); // Initialize Google Analytics when the component mounts

    const updateBodyWidth = () => {
      if (containerRef.current && containerRef.current.offsetWidth > document.body.offsetWidth) {
        document.body.style.width = `${containerRef.current.offsetWidth+40}px`;
        appRef.current.style.width = `${containerRef.current.offsetWidth+40}px`;
      }
    };

    updateBodyWidth(); // Call when component mounts or updates

    // Optional: Set up an event listener if you expect the width to change dynamically (e.g., window resize)
    window.addEventListener('resize', updateBodyWidth);
    return () => {
      window.removeEventListener('resize', updateBodyWidth);
    };
  }, [timers]); // Re-run effect if timers change

  const addTimer = () => {
    setTimers([...timers, <Timer key={timers.length} />]);
    // Optionally track the addition of timers as an event
    ReactGA.event({
      category: 'User',
      action: 'Added a timer'
    });
  };

  return (
    <Appli ref={appRef}>
      <Banner />
      <C>
        <DescriptionApp>
          Combien-de-temps.com is a versatile web application that allows users to manage multiple customizable timers simultaneously. It's perfect for professionals, students, and anyone looking to track multiple tasks efficiently.
        </DescriptionApp>
        <S ref={containerRef}>
          {timers}
          <PlusContainer>
            <PlusButton onClick={addTimer}>+</PlusButton>
          </PlusContainer>
        </S>
      </C>
    </Appli>
  );
}

export default App;

