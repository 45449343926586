import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { select } from 'd3';
import { arc } from 'd3-shape';
import englishData from '../data.json';
import { AiOutlineClose } from "react-icons/ai";

const P = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: #3c3c3c; // Background color for dark theme
  box-shadow: inset 5px 5px 10px #2b2b2b, inset -5px -5px 10px #4d4d4d; // Neumorphism effect for dark theme
`;

const C = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3c3c3c;
  border-radius: 15px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 10px 10px 30px #2b2b2b, -10px -10px 20px #4d4d4d;
  gap: 10px;
  width: 200px;
`;

// const T = styled.h1`
//   margin: 0px;
//   color: #f0f0f0; // Text color for dark theme
// `;

const T = styled.input`
    margin: 0px;
    color: #f0f0f0;
    background-color: #4d4d4d;
    border: none;
    text-align: center;
    font-size: 25px;
    border-radius: 5px;
    width: 90%;
`;

const N = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: #f0f0f0; // Timer color for dark theme
`;

const B = styled.button`
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #0056b3;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #0056b3;
  }
`;

const DropdownMenu = styled.select`
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #0056b3;
  background-color: #3c3c3c; // Background color for dark theme
  color: #f0f0f0; // Text color for dark theme
  cursor: pointer;
  border-radius: 5px;
`;

const DropdownContainer = styled.div`
  width: fit-content;
  display: contents;
`;

const DropdownItem = styled.option``;

const TimerInput = styled.input`
  margin: 5px;
  padding: 10px;
  font-size: 16px;
  border: none;
  background-color: #2b2b2b;
  color: white;
  width: 60px;
  text-align: center;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const StartButton = styled.button`
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #2b2b2b;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #1e1e1e;
  }
  &:focus {
    outline: none;
  }
`;

const ContainerCloseButton = styled.div`
  padding-right: 20px;
  width: 100%;
`;

const CloseButton = styled.button`
    display: flex;
    float: right;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgb(60, 60, 60);
    border: none;
    cursor: pointer;
    box-shadow: rgb(43, 43, 43) 5px 5px 10px inset, rgb(77, 77, 77) -5px -5px 10px inset;
    justify-content: center;
    align-items: center;
    &:hover {
    box-shadow: inset 1px 1px 2px #2d3237, inset -1px -1px 2px #464d56;

    svg {
        color: #007bff;
    }
  }
`;

const CustomTimerContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

const Timer = () => {
    const [t, setT] = useState(0);
    const [d, setD] = useState(null);
    const r = React.useRef(null);
    const [itemType, setItemType] = useState('');
    const [customTime, setCustomTime] = useState(null);
    const [visible, setVisible] = useState(true);
    const [isInvalid, setIsInvalid] = useState(false);
    const values = englishData;

    const [title, setTitle] = useState('Timer');

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleCustomTimeChange = (e) => {
        setCustomTime(e);
    };

    const handleInputChange = (e) => {
        const input = e.target.value;
        const filteredInput = input.replace(/[^0-9]/g, '');

        if (input !== filteredInput) {
            setIsInvalid(true);
        } else {
            setIsInvalid(false);
        }

        handleCustomTimeChange(filteredInput);
    };

    const handleStartClick = () => {
        if (customTime !== 0 && customTime > 0) {
            st(parseInt(customTime) * 60);
        }
    };

    const handleSubjectChange = (e) => {
        setItemType(e.target.value);
    };

    const handleTimeChange = (e) => {
        st(parseInt(e.target.value));
        
        const selectedOption = values[itemType].options.find(option => option.time.toString() === e.target.value);
        const selectedItemType = values[itemType].name;

        if (selectedOption) {
            setTitle(selectedItemType +" - "+selectedOption.label); // Set the title to the label of the selected option
        }
    };

    const ft = (s) => `${Math.floor(s / 60)}:${(s % 60).toString().padStart(2, '0')}`;

    useEffect(() => {
        document.title = t >= 0 ? `${ft(t)}` : values.title;
    }, [t, values.title]);

    const draw = useCallback(() => {
        select(r.current).selectAll('*').remove();

        //console.log('draw' + t);

        if (t === 0) {
            //console.log('clear le svg');
            select(r.current).selectAll('*').remove();
            return;
        }

        const svg = select(r.current)
            .append('svg')
            .attr('width', 200)
            .attr('height', 200);

        const rp = t / (d?.initialTime || t);
        const ea = Math.PI * (2 * rp - 0.5);

        // Create the outer shadow arc
        const outerArc = arc()
            .innerRadius(80)
            .outerRadius(100)
            .startAngle(0)
            .endAngle(Math.PI * 2);

        svg.append('path')
            .attr('d', outerArc)
            .attr('transform', 'translate(100, 100)')
            .attr('fill', '#2b2b2b');

        // Create the inner shadow arc
        const innerArc = arc()
            .innerRadius(80)
            .outerRadius(100)
            .startAngle(0)
            .endAngle(Math.PI * 2);

        svg.append('path')
            .attr('d', innerArc)
            .attr('transform', 'translate(100, 100)')
            .attr('fill', '#4d4d4d');

        // Create the progress arc
        const arcPath = arc()
            .innerRadius(80)
            .outerRadius(100)
            .startAngle(-Math.PI / 2)
            .endAngle(ea);

        svg.append('path')
            .attr('d', arcPath)
            .attr('transform', 'translate(100, 100)')
            .attr('fill', t <= 1 ? 'none' : '#007bff');

    }, [t, d]);

    useEffect(() => {
        //console.log("log:", t, d);
        if (t && d) draw();
    }, [t, d, draw]);

    const st = (s) => {
        if (d) clearInterval(d.id);
        setT(s);
        const id = setInterval(() => setT((p) => p <= 1 ? clearInterval(id) || 0 : p - 1), 1000);
        setD({ id, initialTime: s });
    };

    const tg = () => {
        if (d) {
            clearInterval(d.id);
            setD(null);
        } else if (t > 0) {
            const id = setInterval(() => setT((p) => p <= 1 ? clearInterval(id) || 0 : p - 1), 1000);
            setD({ id, initialTime: t });
        }
    };

    return (
        <>{visible && (
            <C>
                <ContainerCloseButton>
                    <CloseButton aria-label="Closebutton" onClick={() => setVisible(false)}>
                        <AiOutlineClose />
                    </CloseButton>
                </ContainerCloseButton>
                <T
                    type="text"
                    placeholder="Timer"
                    value={title}
                    onChange={handleTitleChange}
                />
                <P>
                    <div ref={r}></div>
                    <N>{ft(t)}</N>
                </P>
                <B onClick={tg}>{d ? values.stopLabel : values.resumeLabel}</B>
                <CustomTimerContainer>
                    <TimerInput
                        type="number"
                        placeholder="Min"
                        value={customTime || ''}
                        onChange={handleInputChange}
                        style={{
                            boxShadow: isInvalid ? 'red 0px 0px 5px' : 'initial',
                        }}
                    />
                    <StartButton onClick={handleStartClick}>Start</StartButton>
                </CustomTimerContainer>
                <DropdownContainer>
                    <DropdownMenu aria-label="Recipes" onChange={handleSubjectChange} value={itemType}>
                        <DropdownItem value="" disabled>
                            {values.selectItemLabel}
                        </DropdownItem>
                        {Object.keys(values).map((key) => {
                            if (['title', 'stopLabel', 'resumeLabel', 'description', 'eggFacts', 'selectItemLabel', 'selectTimeLabel'].includes(key)) return null;
                            return (
                                <DropdownItem key={key} value={key}>
                                    {values[key].name}
                                </DropdownItem>
                            );
                        })}
                    </DropdownMenu>
                    {itemType && (
                        <DropdownMenu onChange={handleTimeChange} defaultValue="">
                            <DropdownItem value="" disabled>
                                {values.selectTimeLabel}
                            </DropdownItem>
                            {values[itemType].options.map((option, index) => (
                                <DropdownItem key={index} value={option.time}>
                                    {option.label} ({ft(option.time)})
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    )}
                </DropdownContainer>
            </C>
        )}
        </>
    );
};

export default Timer;
