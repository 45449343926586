import React from 'react';
import styled from 'styled-components';

const BannerContainer = styled.div`
  background-color: #212121;  // Feel free to change the color to fit your design
  color: white;
  text-align: left;
  padding: 10px 0px 10px 0px;
  width: 100%;
`;

const SiteTitle = styled.h1`
  margin: 0;
  font-size: 1.5em;
  padding-left: 20px;
`;

const Banner = () => {
    return (
        <BannerContainer>
            <SiteTitle>combien-de-temps.com</SiteTitle>
        </BannerContainer>
    );
};

export default Banner;
